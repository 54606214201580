import { ColDef, GridApi } from "ag-grid-community";
import * as moment from "moment";
import { formatNumber } from "src/app/util/util";

export const columnDefs: ColDef[] = [
	{ field: 'Address', rowDrag: true, checkboxSelection: true, flex: 1, minWidth: 130 },
	{ headerName: 'List Date', field: 'listDate', valueGetter: p => p.data.listDate && p.data.DOM ? moment(p.data.listDate).format('MM/DD/YYYY') : '', width: 115 },
	{ headerName: 'Date Sold', field: 'dateSoldString', width: 115 },
	{ headerName: 'DOM', field: 'DOM', valueGetter: p => p.data.DOM === null ? 'N/A' : p.data.DOM, valueFormatter: p => p.data.DOM === null ? 'N/A' : `${p.data.DOM} days`, width: 115 },
	{ headerName: 'Since Sale', field: 'daysSinceSale', valueGetter: p => p.data.dateSoldString ? moment().diff(moment(p.data.dateSoldString), 'days'): '', valueFormatter: p =>  p.data.dateSoldString ? moment().diff(moment(p.data.dateSoldString), 'days') + ' days': '', width: 115  },
	{ headerName: 'Distance (crow flies)', field: 'distance_crowFlies_miles', valueFormatter: p => `${p.data.distance_crowFlies_miles} mi`, width: 115 },
	{ headerName: 'Living Area', field: 'livingArea', valueFormatter: p => `${p.data.livingArea} sq. ft.`, width: 115  },
	{ headerName: 'Sale Price', field: 'price', valueGetter: p => `$${(formatNumber(p.data.price, 'N/A'))}`, width: 115 },
	{ headerName: 'Sale Price / Sq. Ft.', field: 'pricePerSqFoot', valueFormatter: p => `$${p.data.pricePerSqFoot} / sq. ft.`, width: 120 },
	{ headerName: 'Beds', field: 'bedrooms', width: 85 },
	{ headerName: 'Baths', field: 'bathrooms'},
]

export function fmtAddr(c) {
	return `${c.address.streetAddress}, ${c.address.city}, ${c.address.state} ${c.address.zipcode}`
}

export function mapCompsToGrid(c) {
	return {
		...c,
		Address: fmtAddr(c),
		pricePerSqFoot: Math.round((c.price as number) / (c.livingArea as number)),
		distance_crowFlies_miles: c.distance_crowFlies_miles.toFixed(2)
	}
}

export function getRowData(grid: GridApi) {
	let nodes = []
	grid.forEachNodeAfterFilterAndSort(n => nodes.push(n.data))
	return nodes
}
