import { OnInit, Component, Input } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { PropertyDetailsModal } from "src/app/pages/property-details-modal/property-details-modal.component";
import { PropertyService } from "src/app/services/property.service";
import { getMapsImgUrl } from "src/app/util/util";
import _ from 'lodash'
import { IProperty } from "src/app/interfaces/property.interface";

@Component({
  selector: 'property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})

export class PropertyCard implements OnInit {
  @Input() property: IProperty;
  imgUrl: string;
  addressLabel: string;
  
  get VALU() {
    const v = _.get(this.property, 'eccovals.0.priceadjustment')
    return v ? `$${Math.round(v).toLocaleString('en-US')}` : 'N/A'
  }
  get beds() {
    const v = this.property.BedroomsTotal as number
    return isNaN(v) ? 'N/A' : v
  }
  get baths() {
    const v = this.property.BathroomsTotalInteger as number
    return isNaN(v) ? 'N/A' : v
  }
  get livingArea() {
    const v = this.property.LivingArea as number
    return isNaN(v) ? 'N/A' : v.toLocaleString('en-US')
  }

  constructor(
    public propertyService: PropertyService, 
    public modalController: ModalController,
    public nav: NavController
  ) {}

  ngOnInit(): void {
    if (this.property.images.length > 0) {
      this.imgUrl = this.propertyService.getImage(this.property.thumbnail)
    }
    // TODO - this is cool, it pulls the maps img. ill move forward with empty images for now
    // } else if (this.property.search_formatted_address) {
    //   this.imgUrl = getMapsImgUrl(this.property.search_formatted_address)
    // } else {
    //   this.imgUrl = 'assets/images/property/property-1.jpg'
    // }
    // TODO test this with manually created properties
    this.addressLabel = this.property.search_formatted_address 
      ? this.property.search_formatted_address.replace(', USA', '')
      : this.property.formatted_address
  }

  // member methods
  viewDetail(id) {
    this.nav.navigateForward(['property', id]);
  }
}
