import { Component, ViewChild } from '@angular/core';

import { Platform, NavController, Events, ToastController, MenuController, IonNav } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Pages } from './interfaces/pages';

import { AuthService } from './services/auth.service';
import { AppService } from './services/appservice.service';

import _ from 'lodash';
import { ScriptService } from './services/script.service';
import { Subscriber } from 'rxjs';

// managed by build scripts.
import { AppInfoService } from 'src/app/services/appinfo.service';

/**
 * @NOTE the application automatic rerouting happens in this page
 */

export interface SecurePages extends Pages {
  roles?: Array<string>;
}

interface TabLayout {
  userPages: SecurePages[]
  adminPages: SecurePages[]
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(IonNav) nav: IonNav;

  showMenu = false;

  public appPages: TabLayout = { userPages: [], adminPages: [] };
  public user: any = {
    firstname: 'none',
    lastname: 'none',
    roles: [],
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public auth: AuthService,
    public events: Events,
    public toastController: ToastController,
    public appService: AppService,
    public menuCtrl: MenuController,
    public scriptService: ScriptService,
    public appInfoService: AppInfoService,
  ) {

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.menuCtrl.close();
      this.menuCtrl.enable(false, 'main');
      // commented out 2-22-23: not using square anymore
      // this.auth.getSquareWebToken().subscribe(webToken => {
      //   this.scriptService.load('square-' + webToken.squareEnv);
      // });
      this.splashScreen.hide();
    }, (err) => {
      console.log('error bootstraping application: ', err);
    });

    this.events.subscribe('user:logout', () => {
      this.appPages = { userPages: [], adminPages: [] };
      this.navCtrl.navigateRoot('');
    });

    this.events.subscribe('user:profile', (user: any) => {
      this.user = user;
      // do this after login so we get the security applied.
      this.loadMenu(user);
    });

    //// events.publish('service.exception', err);
    this.events.subscribe('service.exception', (err: any) => {
      // this.error = err;
      if (this.appInfoService.svcVersion.online) {
        return this.presentErrors(this.getErrMsg(err));
      }
    });

    if (window.location.pathname.includes('guest/')) {
      return console.log('Not checking authentication')
    }
    this.auth.isAuthenticated().subscribe(
      async (loggedin: Boolean) => {
        if (loggedin) {
          if (window.location.href.includes('/success')) {
            // alert("here!!")
            await this.auth.refreshtoken();
            return
          }

          // NOTE: if you want to hide the menu you can do that here, if the user is logged in but not active
          if (this.user.inactive) {
            console.log('- not loading the menu for unauthorized user...')
          } 
          // TESTING: new property page should not have the nav bar forced in them
          if (window.location.href.includes('/property')) {
            console.log('TESTING: hiding menu')
            return this.menuCtrl.enable(false)
          }
          else {
            this.menuCtrl.enable(true, 'main');
          }

          const url = window.location.pathname
          const user = this.auth.getUser()
          // const user = await this.auth.reloadUser()
          if (url.includes('/property/') || url.includes('edit-profile') || url.includes('subscription') || url.includes('activate') || url.includes('guest')) {
            console.log("Not rerouting page")
          } else if (user.inactive) {
            // todo: find the step that's not completed and go there
            this.navCtrl.navigateForward('register?resume=true')

          } else {
            console.log("Rerouting back home")
            this.navCtrl.navigateRoot('home')
          }
        } else {
          this.menuCtrl.enable(false, 'main');

          console.log("--- HREF = ", window.location.href)
          // if (!window.location.href.includes('/success')) {
          if (!window.location.href.includes('/activate')) {
            console.log("-- logging out")
            this.auth.signOut();
          } else {
            console.log("--- do oyur thing")
          }
        }
      });

  }

  private getErrMsg(error) {
    if (error.name === 'HttpErrorResponse') {
      const err = (error.error ? error.error : error);
      return (err.message.error ? err.message.error : err.message);
    } else if (error.statusCode != null) {
      return (error.message.error ? error.message.error : error.message);
    } else {
      return (error.message ? error.message : error.error);
    }
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    this.menuCtrl.enable(false, 'main');
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.navCtrl.navigateRoot('');
  }

  async presentErrors(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 6000,
      position: 'top'
    });
    toast.present();
  }


  applyMenuSecurity(user, menuItems: Array<SecurePages>) {
    return menuItems.filter(function (menuItem) {
      return ((!menuItem.roles || menuItem.roles.length < 1) || (_.intersection(menuItem.roles, user.roles).length > 0));
    });
  }

  // menu helper to fetch relevant groupings of tabs
  getTabLayout(user, menuItems: SecurePages[]) {
    const accessibleTabs = this.applyMenuSecurity(user, menuItems)
    const userPages = this.applyMenuSecurity(user,menuItems).filter(p => !p.roles || (p.roles && !p.roles.includes('admin')))
    const adminPages = this.applyMenuSecurity(user,menuItems).filter(p => p.roles && p.roles.includes('admin'))
    return { userPages, adminPages }
  }

  loadMenu(user) {
    if (user.inactive) {
      return
    }
    this.menuCtrl.enable(true, 'main');

    const tabs = [
      {
        title: 'Properties',
        url: '/home',
        direct: 'root',
        icon: 'home'
      },
      // we don't even use this. All companies in herit the original org
      // {
      //   title: 'Organization Admin',
      //   url: '/admin/org',
      //   direct: 'forward',
      //   icon: 'person',
      //   roles: ['admin']
      // },
      {
        title: 'Users',
        url: '/admin/users',
        direct: 'forward',
        icon: 'body',
        roles: ['admin']
      },
      {
        title: 'All Properties',
        url: '/admin/property',
        direct: 'forward',
        icon: 'hammer',
        roles: ['admin']
      },
      {
        title: 'Modifiers',
        url: '/admin/lboadmin',
        direct: 'forward',
        icon: 'cog',
        roles: ['admin']
      },
    ]

    // update 3.28.23 - transition into team account is natural
    // if (user.account_lead && !['BETA', 'INDIVIDUAL'].includes(user.tier)) {
    if (user.account_lead) {
      tabs.push({
        title: 'Team',
        url: '/team',
        direct: 'forward',
        icon: 'people',
      })
    }

    tabs.push({
      title: 'Calculator',
      url: '/simulator',
      direct: 'forward',
      icon: 'calculator',
    })

    // this.appPages = this.applyMenuSecurity(user, tabs)
    this.appPages = this.getTabLayout(user, tabs)
  }

  disableMenu(url) {
    return window.location.pathname.startsWith(url);
  }

  reload() {
    return window.location.href;
  }

  /**
   * @todo verify this is working on other browsers - it's not working on mine
   */
  openMailTo() {
    window.location.href = 'mailto:support@VALUclick.co';
  }
}
