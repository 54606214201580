import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ColDef, ICellRendererFunc } from 'ag-grid-community';
import { IProperty } from '../../interfaces/property.interface';
import { delay, abbreviateNumber } from '../../util/util';
import { PropertyService } from '../../services/property.service';
import { PropertyService2 } from 'src/app/services/property2.service';	
import { AgGridAngular } from 'ag-grid-angular';
import { MESSAGE_2 } from 'src/app/components/share-valu-modal/share-valu-constants';
import { AuthService } from 'src/app/services/auth.service';
import { ModalController, ToastController } from '@ionic/angular';
import { UserAdminService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { IUser, UserLeadgenTemplate } from 'src/app/interfaces/user.interface';
// import { ImageAndAddressCell } from './ImageAndAddressCell';

interface EmailTemplateUpdates {
  template: UserLeadgenTemplate
  backendSynced: boolean
}

@Component({
  selector: 'app-lead-gen-page',
  templateUrl: './lead-gen-page.component.html',
  styleUrls: ['./lead-gen-page.component.scss']
})
export class LeadGenPageComponent implements OnInit, OnChanges {
  @Input() properties: IProperty[]
  public emailableProperties: IProperty[]
  public emailedProperties: IProperty[]
  public selectedProperties: IProperty[] = []
  public selectedProperties_missingEmail: IProperty[] = []
  @ViewChild('emailablePropertiesGrid') emailablePropertiesGrid!: AgGridAngular;
  public columnDefs: ColDef[] = [
    { 
      headerName: 'Address',
      valueGetter: params => params.data.search_formatted_address.replace(', USA', ''),
      flex: 1,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      rowDrag: true,
      cellRenderer: params => {
        const property = params.data
        const imgUrl = property.images.length > 0
          ? this.propertyService.getImage(property.thumbnail) 
          : 'assets/images/property/no-photo-available.svg'
        return `
          <div>
            <img src="${imgUrl}" height="80px" style="max-width: 120px"> ${params.value}
          </div>
        `
      }
    },
    { 
      headerName: 'CMA Price', 
      valueGetter: params => params.data.eccovals[0].cmaprice, 
      valueFormatter: params => `$${abbreviateNumber(params.value)}`,
      width: 135
      // https://www.ag-grid.com/javascript-data-grid/component-header/
      // headerComponent will allow a a toggle to view price vs price per sq foot
    },
    { 
      headerName: 'VALU Price',
      valueGetter: params => params.data.eccovals[0].priceadjustment, 
      valueFormatter: params => `$${abbreviateNumber(params.value)}`,
      width: 135
    },
    // { 
    //   headerName: 'Owner Name',
    //   valueGetter: () => 'N/A',
    //   width: 160
    // },
    { 
      headerName: 'Owner Email',
      valueGetter: p => {
        const { owner } = p.data
        if (!owner || !owner.email) return 'N/A'
        return owner.email
      }
    }
  ]
  public selectedTemplate: UserLeadgenTemplate // = DEFAULT_TEMPLATE(this.authService.getUser())
  public selectedTemplateIsReady: boolean
  @Input() loadProperties: () => {}
  public leadgenSubscription: boolean

  constructor(
    public propertyService: PropertyService,
    public authService: AuthService,
    private modalController: ModalController,
    private toastCtl: ToastController,
    private userService: UserAdminService,
    public propertyService2: PropertyService2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.properties) {
      this.emailableProperties = changes.properties.currentValue.filter(p => p.eccovals.length === 1)
      this.emailedProperties = changes.properties.currentValue.filter(p => p.eccovals.length > 1)
    }
  }

  async ngOnInit() {
    /** @todo fix the positioning */
    $(document).on('shown.bs.modal', '.modal', function () {
      $('.modal-backdrop').before($(this));
    });
    const sub = await this.userService.getLeadgenSubscription(this.authService.getUser()._id)
    this.leadgenSubscription = sub.hasLeadgenSub
  }

  async showToast(message) {
    const toast = await this.toastCtl.create({ message, position: 'top', duration: 3000 })
    await toast.present()
  }

  switchTabs(id) {
    // handle the tabs
    $('.subtabs-tabs-active').removeClass('subtabs-tabs-active')
    $(`#${id}`).addClass('subtabs-tabs-active')
    // handle the content
    $(`#subtabs-content > div:not(${id})`).addClass('d-none')
    $(`#subtabs-content-${id}`).removeClass('d-none')
  }

  onRowSelected(event) {
    this.selectedProperties = event.api.getSelectedRows()
  }

  selectProperties() {
    this.selectedProperties_missingEmail = this.selectedProperties.filter(p => !(p.owner && p.owner.email && this.validateEmail(p.owner.email)))
    if (this.selectedProperties_missingEmail.length) {
      return $('#modaltrigger').click()
    }
    this.switchTabs('writeEmail')
    $('.nw-editor').css('height', '545px')
  }

  setEmailsIsDisabled() {
    // return !!this.selectedProperties_missingEmail.find(p => !(p.owner && p.owner.email && !this.validateEmail(p.owner.email))
    // only submit if all exist and are valid
    const missing = this.selectedProperties_missingEmail
    const valid = missing.filter(p => p.owner && p.owner.email && this.validateEmail(p.owner.email))
    return valid.length !== missing.length
  }

  validateEmail(email) {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return isValid;
  }

  /** @todo error handling */
  async updateEmails() {
    await Promise.all(
      this.selectedProperties_missingEmail.map(p => this.propertyService.save(p).toPromise())
    ) 
    this.selectedProperties_missingEmail = []
    $('#closeMissingEmailPropsBtn').click()
    this.selectProperties()
  }

  updatePropertyEmail = evt => {
    const id = evt.target.id.split('_')[0]
    const val = evt.target.value
    const idx = this.selectedProperties_missingEmail.findIndex(p => p._id === id)
    if (!this.selectedProperties_missingEmail[idx].owner) {
      this.selectedProperties_missingEmail[idx].owner = { email: null, name: null }
    }
    this.selectedProperties_missingEmail[idx].owner.email = val
  }

  get onConfirmTab() {
    return !$('#subtabs-content-confirm').hasClass('d-none')
  }

  cancelEmail() {
    if (confirm('Are you sure you would like to cancel this email batch?\nSelected properties and email template will be discared.')) {
      this.selectedProperties = []
      this.switchTabs('selectProperties')
      this.emailablePropertiesGrid.api.deselectAll()
    }
  }
  
  onEmailUpdated(e: EmailTemplateUpdates) {
    this.selectedTemplate = e.template
    this.selectedTemplateIsReady = e.backendSynced
  }

  get confirmEmailTemplateIsDisabled(): boolean {
    return !(this.selectedTemplate && this.selectedTemplateIsReady)
  }

  /**
   * Only display the emailed properties section if we're on that stage.
   */
  shouldViewEmailedProperties() {
    const activeTab = $('.subtabs-tabs-active')
    return activeTab.attr('id') === 'selectProperties'
  }

  async confirm() {
    /** @todo wanted scroll animation */
    this.switchTabs('confirm') 
  }

  async sendEmails() {
    const emailCount = this.selectedProperties.length
    const msg = `Are you sure you would like to send this email to ${emailCount} owners?`
    if (confirm(msg)) {
      try {
        const res = await this.propertyService2.sendLeadgenTemplateLink(this.selectedProperties, this.selectedTemplate)
        const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: `Successfully submitted ${emailCount}`})
        toast.present()
        await this.reset()
      } catch (e) {
        console.error('Error sending emails: ', e)
        const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: `ERROR submitted ${emailCount} emails.`})
        toast.present()
      }
    }
  }

  /** @todo */
  async reset() {
    // get the page state back to normal
    this.selectedProperties = []
    this.selectedProperties_missingEmail = []
    this.emailedProperties = []
    this.emailableProperties = []
    this.selectedTemplate = null
    this.selectedTemplateIsReady = null
    // @todo have the parent load fresh data
    if (this.loadProperties) {
      await this.loadProperties()
    }
    this.switchTabs('selectProperties')
  }

  // ------ emailed properties
  getLastEmailedStats(property: IProperty) {
    const lastEmail = property.eccovals[1].emailed_to[property.eccovals[1].emailed_to.length - 1]
    const fromNow = moment(lastEmail.sent_at).fromNow()
    return `Emailed ${fromNow}`
  }
  getEngagementStats(property: IProperty) {
    const { guestclicks, guestviews} = property.eccovals[1]
    return `${guestviews} Views, ${guestclicks} Adjustments`
  }
  getFormattedNumber(price: number) {
    return abbreviateNumber(price)
  }

  // ------ upgrade options
  get shouldDisplayUpgradeButton() {
    console.log('---- leadgen subscription: ', this.leadgenSubscription, ' ... and display? ', !!this.leadgenSubscription)
    return !this.leadgenSubscription
  }
  async upgrade() {
    if (window.confirm('Are you sure you would like to upgrade?\n\nBy choosing to upgrade, you\'ll enjoy unlimited lead generation emails. Starting next month, your subscription will reflect an additional charge of $50 per month. Please note that for the current month, a prorated amount will be billed to cover the remaining time.')) {
      try {
        const { subscription } = await this.userService.upgradeLeadgenSubscription(this.authService.getUser())
        this.leadgenSubscription = subscription
      } catch (e) {
        console.error('Error upgrading subscription: ', e)
        await this.showToast('Error upgrading subscription')
      }
    }
  }
}
